.search-box > div:first-child {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.search-box .combo .rw-widget-input {
    border-radius: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.search-box .autocomplete-input {
    padding: 11px;
}