/* CustomAutocomplete.css */

.custom-autocomplete {
  position: relative;
  width: 100%;
  display: flex;
  margin: auto;
}

.custom-autocomplete input {
  width: calc(100% - 40px); /* Make space for the search button */
  padding: 12px;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  outline: none;
  transition: all 0.3s ease;
}

.custom-autocomplete button {
  width: 40px;
  padding: 12px 0px;
  background-color: #4a90e2; /* Matches the blue color in the screenshot */
  color: white;
  border: none;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
}

.custom-autocomplete button:hover {
  background-color: #357abd; /* Darken on hover */
}

.custom-autocomplete ul {
  position: absolute;
  top: 85%;
  left: 0;
  width: calc(100% - 95px);
  background: white;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1000;
}

.custom-autocomplete li {
  padding: 10px 15px;
  cursor: pointer;
}

.custom-autocomplete li:hover {
  background-color: #f0f0f0;
}

.loading-spinner {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 10px;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
