.info-window {
  z-index: 3600000;
  font-family: "Arial", sans-serif;
  max-width: 250px;
  color: #444;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.info-window h2 {
  font-size: 20px;
  margin: 0;
  background-color: #ff6347;
  color: white;
  padding: 10px;
  border-bottom: 3px solid #ff4500;
  border-radius: 10px 10px 0 0;
}

.info-window p {
  margin: 10px;
  font-size: 14px;
  line-height: 1.6;
}

.info-window p b {
  color: #ff4500;
}

.info-window img {
  width: 100%;
  border-bottom: 3px solid #ff4500;
  max-height: 150px;
  object-fit: cover;
  margin-bottom: 5px;
}

.info-window .attribution {
  font-size: 12px;
  text-align: center;
  color: #888;
  margin-bottom: 10px;
}

.info-window a {
  text-decoration: none;
  color: #ff6347;
  font-weight: bold;
}
