@import "./theme/global/tailwindcss.css";
@tailwind components;
:root {
  --color-primary: #1b3ffc;
  --color-primary-light: #1bd8fe;
}
html,
body,
#root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
@layer components {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid inherit;
    -webkit-text-fill-color: white;
    box-shadow: 0 0 0px 1000px none inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  /* Firefox */
  input:-moz-autofill,
  textarea:-moz-autofill,
  select:-moz-autofill {
    box-shadow: 0 0 0px 1000px none inset;
    -moz-text-fill-color: white;
  }

  /* Edge */
  input:-ms-autofill,
  textarea:-ms-autofill,
  select:-ms-autofill {
    box-shadow: 0 0 0px 1000px none inset;
    -ms-text-fill-color: white;
  }
}
.react-datepicker__input-container {
  width: 94% !important;
}
.custom-border > div {
  border: solid 1px #fff;
}

.gm-style-iw[role="dialog"] {
  max-width: 700px !important;
}

.info-window {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  max-width: 700px;
  padding: 10px;
}

.info-content a {
  color: #007bff;
  text-decoration: none;
}

.info-content p {
  margin: 5px 0;
  color: #555;
}

.warning-text {
  color: #d9534f;
  font-weight: bold;
  margin: 5px 0;
}

.info-footer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.info-footer p {
  margin: 5px 0;
  color: #333;
}

.price {
  font-size: 20px;
  color: #000;
  font-weight: bold;
}

.custom-btn {
  display: inline-block;
  background-color: #007bff;
  color: #fff !important;
  padding: 8px 12px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 5px;
}
.gmnoprint:has(button[title="Zoom in"]) {
  bottom: 250px !important;
}
button[title="Toggle fullscreen view"] {
  top: 35px !important;
}
.gm-compass {
  bottom: 0px;
}
div[dir="ltr"]:has(div > button[title="Exit the Street View"]) {
  top: 30px !important;
}
div[dir="ltr"]:has(div.gm-iv-address) {
  top: 30px !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
.sheet-content {
  height: 100%;
  position: relative !important;
  overflow-y: auto;
}
.react-modal-sheet-header {
  height: 20px !important;
}
.vAygCK-api-load-alpha-banner {
  display: none;
}
.heart-shape {
  clip-path: polygon(50% 0%, 100% 38%, 100% 100%, 50% 80%, 0% 100%, 0% 38%);
}
