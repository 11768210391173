.fancy-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 400px;
}

.input-wrapper {
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  background-color: #f7f7f7;
  width: 100%;
}

.fancy-location-input {
  flex-grow: 1;
  padding: 7px;
  border: none;
  font-size: 14px;
  outline: none;
  background: transparent;
}

.clear-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 3px;
  color: red;
  position: relative;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
  right: 6px !important;
}

.clear-button:hover {
  background: #d0d0d0;
}

.search-button {
  background-color: #4a90e2; /* Your primary color */
  border: none;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.2s;
}

.search-button:hover {
  background-color: #357abd;
}

.autocomplete-suggestions {
  margin-top: 5px;
  list-style-type: none;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.autocomplete-suggestion {
  padding: 8px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}

.autocomplete-suggestion:last-child {
  border-bottom: none;
}

.autocomplete-suggestion:hover {
  background-color: #f0f0f0;
}

.loading-spinner {
  text-align: center;
  margin-top: 10px;
}
