*, ::before, ::after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000
}

::backdrop {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000
}

* {
  font-family: Roboto
}

h1, h2, h3, h4, h5, h6, dt {
  margin: 0
}

dl, dt, dd {
  margin: 0;
  padding: 0
}

p {
  line-height: 1.5rem
}

.container {
  width: 100%
}

@media (min-width: 600px) {
  .container {
    max-width: 600px
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 960px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

.visible {
  visibility: visible
}

.static {
  position: static
}

.\!absolute {
  position: absolute !important
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.inset-0 {
  inset: 0
}

.inset-y-0 {
  top: 0;
  bottom: 0
}

.bottom-0 {
  bottom: 0
}

.bottom-10 {
  bottom: 2.6666666666666665rem
}

.bottom-12 {
  bottom: 3.2rem
}

.bottom-18 {
  bottom: 4.8rem
}

.bottom-2 {
  bottom: 0.5333333333333333rem
}

.bottom-3 {
  bottom: 0.8rem
}

.bottom-8 {
  bottom: 2.1333333333333333rem
}

.bottom-\[-10px\] {
  bottom: -10px
}

.bottom-\[-40px\] {
  bottom: -40px
}

.bottom-\[-4px\] {
  bottom: -4px
}

.bottom-\[-5px\] {
  bottom: -5px
}

.bottom-\[100px\] {
  bottom: 100px
}

.bottom-\[10px\] {
  bottom: 10px
}

.bottom-\[130px\] {
  bottom: 130px
}

.bottom-\[1px\] {
  bottom: 1px
}

.bottom-\[50px\] {
  bottom: 50px
}

.bottom-\[5px\] {
  bottom: 5px
}

.bottom-\[80px\] {
  bottom: 80px
}

.bottom-\[9px\] {
  bottom: 9px
}

.left-0 {
  left: 0
}

.left-1 {
  left: 0.26666666666666666rem
}

.left-1\/2 {
  left: 50%
}

.left-12 {
  left: 3.2rem
}

.left-14 {
  left: 3.7333333333333334rem
}

.left-2 {
  left: 0.5333333333333333rem
}

.left-3 {
  left: 0.8rem
}

.left-4 {
  left: 1.0666666666666667rem
}

.left-\[-20px\] {
  left: -20px
}

.left-\[-24px\] {
  left: -24px
}

.left-\[-80px\] {
  left: -80px
}

.left-\[0px\] {
  left: 0px
}

.left-\[10\%\] {
  left: 10%
}

.left-\[130px\] {
  left: 130px
}

.left-\[150px\] {
  left: 150px
}

.left-\[180px\] {
  left: 180px
}

.left-\[20\%\] {
  left: 20%
}

.left-\[200px\] {
  left: 200px
}

.left-\[43px\] {
  left: 43px
}

.left-\[45px\] {
  left: 45px
}

.left-\[50\%\] {
  left: 50%
}

.left-\[50px\] {
  left: 50px
}

.left-\[53px\] {
  left: 53px
}

.right-0 {
  right: 0
}

.right-0\.5 {
  right: 0.13333333333333333rem
}

.right-1 {
  right: 0.26666666666666666rem
}

.right-1\/2 {
  right: 50%
}

.right-2 {
  right: 0.5333333333333333rem
}

.right-3 {
  right: 0.8rem
}

.right-6 {
  right: 1.6rem
}

.right-8 {
  right: 2.1333333333333333rem
}

.right-\[10px\] {
  right: 10px
}

.right-\[145px\] {
  right: 145px
}

.right-\[170px\] {
  right: 170px
}

.right-\[270px\] {
  right: 270px
}

.right-\[55px\] {
  right: 55px
}

.right-\[70px\] {
  right: 70px
}

.top-0 {
  top: 0
}

.top-0\.5 {
  top: 0.13333333333333333rem
}

.top-1 {
  top: 0.26666666666666666rem
}

.top-1\/2 {
  top: 50%
}

.top-1\/3 {
  top: 33.333333%
}

.top-1\/4 {
  top: 25%
}

.top-10 {
  top: 2.6666666666666665rem
}

.top-12 {
  top: 3.2rem
}

.top-2 {
  top: 0.5333333333333333rem
}

.top-3 {
  top: 0.8rem
}

.top-4 {
  top: 1.0666666666666667rem
}

.top-5 {
  top: 1.3333333333333333rem
}

.top-6 {
  top: 1.6rem
}

.top-8 {
  top: 2.1333333333333333rem
}

.top-\[-100px\] {
  top: -100px
}

.top-\[-10px\] {
  top: -10px
}

.top-\[-20px\] {
  top: -20px
}

.top-\[-63\.5px\] {
  top: -63.5px
}

.top-\[-80px\] {
  top: -80px
}

.top-\[100px\] {
  top: 100px
}

.top-\[102px\] {
  top: 102px
}

.top-\[113px\] {
  top: 113px
}

.top-\[12\%\] {
  top: 12%
}

.top-\[133px\] {
  top: 133px
}

.top-\[13px\] {
  top: 13px
}

.top-\[140px\] {
  top: 140px
}

.top-\[15\%\] {
  top: 15%
}

.top-\[15px\] {
  top: 15px
}

.top-\[17px\] {
  top: 17px
}

.top-\[2\%\] {
  top: 2%
}

.top-\[200px\] {
  top: 200px
}

.top-\[22\%\] {
  top: 22%
}

.top-\[24\%\] {
  top: 24%
}

.top-\[30\%\] {
  top: 30%
}

.top-\[30px\] {
  top: 30px
}

.top-\[34\%\] {
  top: 34%
}

.top-\[44\.4\%\] {
  top: 44.4%
}

.top-\[45px\] {
  top: 45px
}

.top-\[46\%\] {
  top: 46%
}

.top-\[5\%\] {
  top: 5%
}

.top-\[54\%\] {
  top: 54%
}

.top-\[55px\] {
  top: 55px
}

.top-\[5px\] {
  top: 5px
}

.top-\[88px\] {
  top: 88px
}

.-z-1 {
  z-index: -1
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-30 {
  z-index: 30
}

.z-40 {
  z-index: 40
}

.z-50 {
  z-index: 50
}

.z-\[1000\] {
  z-index: 1000
}

.z-\[100\] {
  z-index: 100
}

.z-\[200\] {
  z-index: 200
}

.z-\[360000\] {
  z-index: 360000
}

.z-\[36000\] {
  z-index: 36000
}

.z-\[60\] {
  z-index: 60
}

.float-right {
  float: right
}

.mx-1 {
  margin-left: 0.26666666666666666rem;
  margin-right: 0.26666666666666666rem
}

.mx-2 {
  margin-left: 0.5333333333333333rem;
  margin-right: 0.5333333333333333rem
}

.mx-3 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.mx-8 {
  margin-left: 2.1333333333333333rem;
  margin-right: 2.1333333333333333rem
}

.mx-\[20px\] {
  margin-left: 20px;
  margin-right: 20px
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-1 {
  margin-top: 0.26666666666666666rem;
  margin-bottom: 0.26666666666666666rem
}

.my-2 {
  margin-top: 0.5333333333333333rem;
  margin-bottom: 0.5333333333333333rem
}

.my-3 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.my-4 {
  margin-top: 1.0666666666666667rem;
  margin-bottom: 1.0666666666666667rem
}

.my-8 {
  margin-top: 2.1333333333333333rem;
  margin-bottom: 2.1333333333333333rem
}

.my-\[16px\] {
  margin-top: 16px;
  margin-bottom: 16px
}

.my-\[19px\] {
  margin-top: 19px;
  margin-bottom: 19px
}

.my-\[28px\] {
  margin-top: 28px;
  margin-bottom: 28px
}

.my-\[30px\] {
  margin-top: 30px;
  margin-bottom: 30px
}

.my-\[53px\] {
  margin-top: 53px;
  margin-bottom: 53px
}

.my-\[9px\] {
  margin-top: 9px;
  margin-bottom: 9px
}

.mb-1 {
  margin-bottom: 0.26666666666666666rem
}

.mb-2 {
  margin-bottom: 0.5333333333333333rem
}

.mb-3 {
  margin-bottom: 0.8rem
}

.mb-4 {
  margin-bottom: 1.0666666666666667rem
}

.mb-6 {
  margin-bottom: 1.6rem
}

.mb-8 {
  margin-bottom: 2.1333333333333333rem
}

.mb-\[20px\] {
  margin-bottom: 20px
}

.mb-\[33px\] {
  margin-bottom: 33px
}

.mb-\[5rem\] {
  margin-bottom: 5rem
}

.ml-1 {
  margin-left: 0.26666666666666666rem
}

.ml-2 {
  margin-left: 0.5333333333333333rem
}

.ml-4 {
  margin-left: 1.0666666666666667rem
}

.ml-\[10px\] {
  margin-left: 10px
}

.ml-\[11px\] {
  margin-left: 11px
}

.ml-\[20px\] {
  margin-left: 20px
}

.ml-\[2px\] {
  margin-left: 2px
}

.ml-\[7px\] {
  margin-left: 7px
}

.mr-1 {
  margin-right: 0.26666666666666666rem
}

.mr-2 {
  margin-right: 0.5333333333333333rem
}

.mr-3 {
  margin-right: 0.8rem
}

.mr-4 {
  margin-right: 1.0666666666666667rem
}

.mr-\[-40px\] {
  margin-right: -40px
}

.mr-\[0\.5px\] {
  margin-right: 0.5px
}

.mr-\[11px\] {
  margin-right: 11px
}

.mr-\[20px\] {
  margin-right: 20px
}

.mt-1 {
  margin-top: 0.26666666666666666rem
}

.mt-10 {
  margin-top: 2.6666666666666665rem
}

.mt-2 {
  margin-top: 0.5333333333333333rem
}

.mt-3 {
  margin-top: 0.8rem
}

.mt-4 {
  margin-top: 1.0666666666666667rem
}

.mt-5 {
  margin-top: 1.3333333333333333rem
}

.mt-6 {
  margin-top: 1.6rem
}

.mt-8 {
  margin-top: 2.1333333333333333rem
}

.mt-\[20px\] {
  margin-top: 20px
}

.mt-\[25px\] {
  margin-top: 25px
}

.mt-\[34px\] {
  margin-top: 34px
}

.mt-\[80px\] {
  margin-top: 80px
}

.mt-\[9px\] {
  margin-top: 9px
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.\!h-24 {
  height: 6.4rem !important
}

.\!h-full {
  height: 100% !important
}

.h-0 {
  height: 0
}

.h-1\/2 {
  height: 50%
}

.h-10 {
  height: 2.6666666666666665rem
}

.h-12 {
  height: 3.2rem
}

.h-14 {
  height: 3.7333333333333334rem
}

.h-16 {
  height: 4.266666666666667rem
}

.h-2 {
  height: 0.5333333333333333rem
}

.h-20 {
  height: 5.333333333333333rem
}

.h-24 {
  height: 6.4rem
}

.h-25 {
  height: 6.666666666666667rem
}

.h-3 {
  height: 0.8rem
}

.h-4 {
  height: 1.0666666666666667rem
}

.h-5 {
  height: 1.3333333333333333rem
}

.h-56 {
  height: 14.933333333333334rem
}

.h-6 {
  height: 1.6rem
}

.h-64 {
  height: 17.066666666666666rem
}

.h-8 {
  height: 2.1333333333333333rem
}

.h-\[100px\] {
  height: 100px
}

.h-\[111px\] {
  height: 111px
}

.h-\[129px\] {
  height: 129px
}

.h-\[14px\] {
  height: 14px
}

.h-\[15px\] {
  height: 15px
}

.h-\[1px\] {
  height: 1px
}

.h-\[20\%\] {
  height: 20%
}

.h-\[200px\] {
  height: 200px
}

.h-\[20px\] {
  height: 20px
}

.h-\[20vh\] {
  height: 20vh
}

.h-\[240px\] {
  height: 240px
}

.h-\[246px\] {
  height: 246px
}

.h-\[250px\] {
  height: 250px
}

.h-\[25px\] {
  height: 25px
}

.h-\[28px\] {
  height: 28px
}

.h-\[300px\] {
  height: 300px
}

.h-\[30px\] {
  height: 30px
}

.h-\[33px\] {
  height: 33px
}

.h-\[35px\] {
  height: 35px
}

.h-\[370px\] {
  height: 370px
}

.h-\[375px\] {
  height: 375px
}

.h-\[40\%\] {
  height: 40%
}

.h-\[40px\] {
  height: 40px
}

.h-\[41vh\] {
  height: 41vh
}

.h-\[49px\] {
  height: 49px
}

.h-\[5\%\] {
  height: 5%
}

.h-\[500px\] {
  height: 500px
}

.h-\[50px\] {
  height: 50px
}

.h-\[50vh\] {
  height: 50vh
}

.h-\[515px\] {
  height: 515px
}

.h-\[55px\] {
  height: 55px
}

.h-\[56px\] {
  height: 56px
}

.h-\[60\%\] {
  height: 60%
}

.h-\[60px\] {
  height: 60px
}

.h-\[60vh\] {
  height: 60vh
}

.h-\[65\%\] {
  height: 65%
}

.h-\[71\.3\%\] {
  height: 71.3%
}

.h-\[75px\] {
  height: 75px
}

.h-\[78\%\] {
  height: 78%
}

.h-\[80vh\] {
  height: 80vh
}

.h-\[82\%\] {
  height: 82%
}

.h-\[82px\] {
  height: 82px
}

.h-\[87\%\] {
  height: 87%
}

.h-\[87px\] {
  height: 87px
}

.h-\[89\%\] {
  height: 89%
}

.h-\[89px\] {
  height: 89px
}

.h-\[90px\] {
  height: 90px
}

.h-\[94px\] {
  height: 94px
}

.h-\[95px\] {
  height: 95px
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.max-h-10 {
  max-height: 2.6666666666666665rem
}

.max-h-\[150px\] {
  max-height: 150px
}

.max-h-\[200px\] {
  max-height: 200px
}

.max-h-\[250px\] {
  max-height: 250px
}

.max-h-\[290px\] {
  max-height: 290px
}

.max-h-\[300px\] {
  max-height: 300px
}

.max-h-\[350px\] {
  max-height: 350px
}

.max-h-\[40px\] {
  max-height: 40px
}

.max-h-\[445px\] {
  max-height: 445px
}

.max-h-\[500px\] {
  max-height: 500px
}

.max-h-\[80vh\] {
  max-height: 80vh
}

.max-h-\[94px\] {
  max-height: 94px
}

.min-h-\[75px\] {
  min-height: 75px
}

.min-h-\[95px\] {
  min-height: 95px
}

.\!w-\[100px\] {
  width: 100px !important
}

.\!w-full {
  width: 100% !important
}

.w-0 {
  width: 0
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-1\/5 {
  width: 20%
}

.w-10 {
  width: 2.6666666666666665rem
}

.w-12 {
  width: 3.2rem
}

.w-14 {
  width: 3.7333333333333334rem
}

.w-16 {
  width: 4.266666666666667rem
}

.w-18 {
  width: 4.8rem
}

.w-20 {
  width: 5.333333333333333rem
}

.w-24 {
  width: 6.4rem
}

.w-25 {
  width: 6.666666666666667rem
}

.w-3 {
  width: 0.8rem
}

.w-4 {
  width: 1.0666666666666667rem
}

.w-40 {
  width: 10.666666666666666rem
}

.w-5 {
  width: 1.3333333333333333rem
}

.w-56 {
  width: 14.933333333333334rem
}

.w-6 {
  width: 1.6rem
}

.w-60 {
  width: 16rem
}

.w-72 {
  width: 19.2rem
}

.w-8 {
  width: 2.1333333333333333rem
}

.w-80 {
  width: 21.333333333333332rem
}

.w-\[1\/2\] {
  width: 1/2
}

.w-\[10\%\] {
  width: 10%
}

.w-\[100\%\] {
  width: 100%
}

.w-\[100px\] {
  width: 100px
}

.w-\[112px\] {
  width: 112px
}

.w-\[119px\] {
  width: 119px
}

.w-\[120px\] {
  width: 120px
}

.w-\[124px\] {
  width: 124px
}

.w-\[144px\] {
  width: 144px
}

.w-\[192px\] {
  width: 192px
}

.w-\[1px\] {
  width: 1px
}

.w-\[20\%\] {
  width: 20%
}

.w-\[200px\] {
  width: 200px
}

.w-\[20px\] {
  width: 20px
}

.w-\[233px\] {
  width: 233px
}

.w-\[250px\] {
  width: 250px
}

.w-\[25px\] {
  width: 25px
}

.w-\[26\%\] {
  width: 26%
}

.w-\[28px\] {
  width: 28px
}

.w-\[30\%\] {
  width: 30%
}

.w-\[300px\] {
  width: 300px
}

.w-\[30px\] {
  width: 30px
}

.w-\[328px\] {
  width: 328px
}

.w-\[40\%\] {
  width: 40%
}

.w-\[400px\] {
  width: 400px
}

.w-\[40px\] {
  width: 40px
}

.w-\[410px\] {
  width: 410px
}

.w-\[44px\] {
  width: 44px
}

.w-\[49px\] {
  width: 49px
}

.w-\[50\%\] {
  width: 50%
}

.w-\[50px\] {
  width: 50px
}

.w-\[60\%\] {
  width: 60%
}

.w-\[64\%\] {
  width: 64%
}

.w-\[65px\] {
  width: 65px
}

.w-\[68\%\] {
  width: 68%
}

.w-\[70\%\] {
  width: 70%
}

.w-\[70px\] {
  width: 70px
}

.w-\[76px\] {
  width: 76px
}

.w-\[80\%\] {
  width: 80%
}

.w-\[89px\] {
  width: 89px
}

.w-\[90\%\] {
  width: 90%
}

.w-\[92\%\] {
  width: 92%
}

.w-\[95\%\] {
  width: 95%
}

.w-\[96\%\] {
  width: 96%
}

.w-\[98\%\] {
  width: 98%
}

.w-\[99px\] {
  width: 99px
}

.w-auto {
  width: auto
}

.w-full {
  width: 100%
}

.min-w-10 {
  min-width: 2.6666666666666665rem
}

.min-w-24 {
  min-width: 6.4rem
}

.min-w-\[110px\] {
  min-width: 110px
}

.min-w-\[250px\] {
  min-width: 250px
}

.min-w-\[300px\] {
  min-width: 300px
}

.min-w-\[500px\] {
  min-width: 500px
}

.min-w-\[60px\] {
  min-width: 60px
}

.min-w-\[80px\] {
  min-width: 80px
}

.min-w-max {
  min-width: max-content
}

.max-w-10 {
  max-width: 2.6666666666666665rem
}

.max-w-48 {
  max-width: 12.8rem
}

.max-w-4xl {
  max-width: 56rem
}

.max-w-\[100px\] {
  max-width: 100px
}

.max-w-\[200px\] {
  max-width: 200px
}

.max-w-\[250px\] {
  max-width: 250px
}

.max-w-\[280px\] {
  max-width: 280px
}

.max-w-\[340px\] {
  max-width: 340px
}

.max-w-\[350px\] {
  max-width: 350px
}

.max-w-\[390px\] {
  max-width: 390px
}

.max-w-\[400px\] {
  max-width: 400px
}

.max-w-\[40px\] {
  max-width: 40px
}

.max-w-\[50\%\] {
  max-width: 50%
}

.max-w-\[70px\] {
  max-width: 70px
}

.max-w-full {
  max-width: 100%
}

.max-w-lg {
  max-width: 32rem
}

.max-w-md {
  max-width: 28rem
}

.flex-1 {
  flex: 1 1 0%
}

.flex-none {
  flex: none
}

.shrink-0 {
  flex-shrink: 0
}

.flex-grow {
  flex-grow: 1
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@keyframes flipRightToLeft {
  0%, 100% {
    transform: rotateY(0deg)
  }

  50% {
    transform: rotateY(-180deg)
  }
}

.animate-flipRightToLeft {
  animation: flipRightToLeft 3s ease-in-out infinite
}

.cursor-pointer {
  cursor: pointer
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-nowrap {
  flex-wrap: nowrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.\!justify-between {
  justify-content: space-between !important
}

.justify-between {
  justify-content: space-between
}

.gap-0 {
  gap: 0
}

.gap-1 {
  gap: 0.26666666666666666rem
}

.gap-10 {
  gap: 2.6666666666666665rem
}

.gap-11 {
  gap: 2.933333333333333rem
}

.gap-2 {
  gap: 0.5333333333333333rem
}

.gap-3 {
  gap: 0.8rem
}

.gap-30 {
  gap: 8rem
}

.gap-4 {
  gap: 1.0666666666666667rem
}

.gap-5 {
  gap: 1.3333333333333333rem
}

.gap-6 {
  gap: 1.6rem
}

.gap-8 {
  gap: 2.1333333333333333rem
}

.gap-\[1px\] {
  gap: 1px
}

.gap-\[6px\] {
  gap: 6px
}

.gap-\[8px\] {
  gap: 8px
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.26666666666666666rem * var(--tw-space-x-reverse));
  margin-left: calc(0.26666666666666666rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5333333333333333rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5333333333333333rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.8rem * var(--tw-space-x-reverse));
  margin-left: calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.0666666666666667rem * var(--tw-space-x-reverse));
  margin-left: calc(1.0666666666666667rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-\[129px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(129px * var(--tw-space-x-reverse));
  margin-left: calc(129px * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-\[12px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12px * var(--tw-space-x-reverse));
  margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.26666666666666666rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.26666666666666666rem * var(--tw-space-y-reverse))
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5333333333333333rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5333333333333333rem * var(--tw-space-y-reverse))
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.8rem * var(--tw-space-y-reverse))
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.0666666666666667rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.0666666666666667rem * var(--tw-space-y-reverse))
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.6rem * var(--tw-space-y-reverse))
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.1333333333333333rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.1333333333333333rem * var(--tw-space-y-reverse))
}

.self-center {
  align-self: center
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-scroll {
  overflow-x: scroll
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.whitespace-nowrap {
  white-space: nowrap
}

.\!rounded-full {
  border-radius: 9999px !important
}

.\!rounded-md {
  border-radius: 0.375rem !important
}

.rounded {
  border-radius: 0.25rem
}

.rounded-10 {
  border-radius: 10px
}

.rounded-3xl {
  border-radius: 1.5rem
}

.rounded-4 {
  border-radius: 4px
}

.rounded-\[10px\] {
  border-radius: 10px
}

.rounded-\[1px\] {
  border-radius: 1px
}

.rounded-\[20px\] {
  border-radius: 20px
}

.rounded-\[30px\] {
  border-radius: 30px
}

.rounded-\[3px\] {
  border-radius: 3px
}

.rounded-\[4px\] {
  border-radius: 4px
}

.rounded-\[5px\] {
  border-radius: 5px
}

.rounded-\[6px\] {
  border-radius: 6px
}

.rounded-\[7px\] {
  border-radius: 7px
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-sm {
  border-radius: 0.125rem
}

.\!rounded-l-none {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important
}

.rounded-b-\[10px\] {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem
}

.rounded-l-\[10px\] {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px
}

.rounded-l-\[20px\] {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px
}

.rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px
}

.rounded-r-\[20px\] {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px
}

.rounded-br-full {
  border-bottom-right-radius: 9999px
}

.\!border {
  border-width: 1px !important
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-2 {
  border-width: 2px
}

.border-\[\.5px\] {
  border-width: .5px
}

.border-b {
  border-bottom-width: 1px
}

.border-l-\[10px\] {
  border-left-width: 10px
}

.border-r-\[10px\] {
  border-right-width: 10px
}

.border-t {
  border-top-width: 1px
}

.border-t-\[10px\] {
  border-top-width: 10px
}

.\!border-solid {
  border-style: solid !important
}

.border-solid {
  border-style: solid
}

.border-none {
  border-style: none
}

.\!border-\[\#0000008a\] {
  border-color: #0000008a !important
}

.border-\[\#000\] {
  border-color: #000
}

.border-\[\#090D42\] {
  border-color: #090D42
}

.border-\[\#150ECF\] {
  border-color: #150ECF
}

.border-\[\#AEA3A3\] {
  border-color: #AEA3A3
}

.border-\[\#B29D9D\] {
  border-color: #B29D9D
}

.border-\[\#C2ACAC\] {
  border-color: #C2ACAC
}

.border-\[\#C6D4FF\] {
  border-color: #C6D4FF
}

.border-\[\#CBB9B9\] {
  border-color: #CBB9B9
}

.border-\[\#D9D9D9\] {
  border-color: #D9D9D9
}

.border-\[\#E7E7E7\] {
  border-color: #E7E7E7
}

.border-\[\#EAE4E4\] {
  border-color: #EAE4E4
}

.border-\[\#FFF0F0\] {
  border-color: #FFF0F0
}

.border-\[\#fff\] {
  border-color: #fff
}

.border-secondary-light {
  border-color: #666666
}

.border-l-transparent {
  border-left-color: transparent
}

.border-r-transparent {
  border-right-color: transparent
}

.border-t-\[\#0BD6F5\] {
  border-top-color: #0BD6F5
}

.border-t-\[\#29B629\] {
  border-top-color: #29B629
}

.border-t-\[\#EF990E\] {
  border-top-color: #EF990E
}

.border-t-\[\#FF0242\] {
  border-top-color: #FF0242
}

.border-t-\[\#fff\] {
  border-top-color: #fff
}

.border-t-primary {
  border-top-color: #1b3ffc
}

.border-t-secondary-l0 {
  border-top-color: #ffffff
}

.\!bg-\[\#0BD6F5\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(11 214 245 / var(--tw-bg-opacity)) !important
}

.\!bg-\[\#1E4BB1\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 75 177 / var(--tw-bg-opacity)) !important
}

.\!bg-\[\#29B629\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(41 182 41 / var(--tw-bg-opacity)) !important
}

.\!bg-\[\#2AA8E2\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(42 168 226 / var(--tw-bg-opacity)) !important
}

.\!bg-\[\#EF990E\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 153 14 / var(--tw-bg-opacity)) !important
}

.\!bg-\[\#FF0242\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 2 66 / var(--tw-bg-opacity)) !important
}

.\!bg-green {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(51 255 231 / var(--tw-bg-opacity)) !important
}

.bg-\[\#000\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-\[\#000\]\/10 {
  background-color: rgb(0 0 0 / 0.1)
}

.bg-\[\#000\]\/20 {
  background-color: rgb(0 0 0 / 0.2)
}

.bg-\[\#000\]\/25 {
  background-color: rgb(0 0 0 / 0.25)
}

.bg-\[\#015EFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 94 255 / var(--tw-bg-opacity))
}

.bg-\[\#01A3FE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 163 254 / var(--tw-bg-opacity))
}

.bg-\[\#061E8D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(6 30 141 / var(--tw-bg-opacity))
}

.bg-\[\#0773FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(7 115 255 / var(--tw-bg-opacity))
}

.bg-\[\#080505\] {
  --tw-bg-opacity: 1;
  background-color: rgb(8 5 5 / var(--tw-bg-opacity))
}

.bg-\[\#0BD6F5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(11 214 245 / var(--tw-bg-opacity))
}

.bg-\[\#0C5CFC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(12 92 252 / var(--tw-bg-opacity))
}

.bg-\[\#0CABCA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(12 171 202 / var(--tw-bg-opacity))
}

.bg-\[\#0D81F5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(13 129 245 / var(--tw-bg-opacity))
}

.bg-\[\#1108FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(17 8 255 / var(--tw-bg-opacity))
}

.bg-\[\#1289F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(18 137 246 / var(--tw-bg-opacity))
}

.bg-\[\#1351BC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(19 81 188 / var(--tw-bg-opacity))
}

.bg-\[\#1357EA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(19 87 234 / var(--tw-bg-opacity))
}

.bg-\[\#137DE6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(19 125 230 / var(--tw-bg-opacity))
}

.bg-\[\#1560D0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 96 208 / var(--tw-bg-opacity))
}

.bg-\[\#170E0E\]\/50 {
  background-color: rgb(23 14 14 / 0.5)
}

.bg-\[\#173A8f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(23 58 143 / var(--tw-bg-opacity))
}

.bg-\[\#193569\] {
  --tw-bg-opacity: 1;
  background-color: rgb(25 53 105 / var(--tw-bg-opacity))
}

.bg-\[\#1A0978\] {
  --tw-bg-opacity: 1;
  background-color: rgb(26 9 120 / var(--tw-bg-opacity))
}

.bg-\[\#1C0A0A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(28 10 10 / var(--tw-bg-opacity))
}

.bg-\[\#279DD4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(39 157 212 / var(--tw-bg-opacity))
}

.bg-\[\#29B629\] {
  --tw-bg-opacity: 1;
  background-color: rgb(41 182 41 / var(--tw-bg-opacity))
}

.bg-\[\#2A6DF1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(42 109 241 / var(--tw-bg-opacity))
}

.bg-\[\#2AB529\] {
  --tw-bg-opacity: 1;
  background-color: rgb(42 181 41 / var(--tw-bg-opacity))
}

.bg-\[\#2D6ADC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(45 106 220 / var(--tw-bg-opacity))
}

.bg-\[\#2b96b3\]\/80 {
  background-color: rgb(43 150 179 / 0.8)
}

.bg-\[\#362B2B80\] {
  background-color: #362B2B80
}

.bg-\[\#3F75FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(63 117 255 / var(--tw-bg-opacity))
}

.bg-\[\#4A90E2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(74 144 226 / var(--tw-bg-opacity))
}

.bg-\[\#4a90e2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(74 144 226 / var(--tw-bg-opacity))
}

.bg-\[\#56B221\] {
  --tw-bg-opacity: 1;
  background-color: rgb(86 178 33 / var(--tw-bg-opacity))
}

.bg-\[\#7FA9DB\] {
  --tw-bg-opacity: 1;
  background-color: rgb(127 169 219 / var(--tw-bg-opacity))
}

.bg-\[\#7fa9db\] {
  --tw-bg-opacity: 1;
  background-color: rgb(127 169 219 / var(--tw-bg-opacity))
}

.bg-\[\#94D5F7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(148 213 247 / var(--tw-bg-opacity))
}

.bg-\[\#BAA8A8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(186 168 168 / var(--tw-bg-opacity))
}

.bg-\[\#C8F6FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(200 246 255 / var(--tw-bg-opacity))
}

.bg-\[\#E3D7D7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(227 215 215 / var(--tw-bg-opacity))
}

.bg-\[\#E4FFF4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(228 255 244 / var(--tw-bg-opacity))
}

.bg-\[\#EDF3FE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 243 254 / var(--tw-bg-opacity))
}

.bg-\[\#EEEAEA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 234 234 / var(--tw-bg-opacity))
}

.bg-\[\#EEEFFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 239 255 / var(--tw-bg-opacity))
}

.bg-\[\#EF990E\] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 153 14 / var(--tw-bg-opacity))
}

.bg-\[\#F0F0F0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity))
}

.bg-\[\#F37485\]\/50 {
  background-color: rgb(243 116 133 / 0.5)
}

.bg-\[\#FBAD3C\] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 173 60 / var(--tw-bg-opacity))
}

.bg-\[\#FCFFBE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 255 190 / var(--tw-bg-opacity))
}

.bg-\[\#FF0242\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 2 66 / var(--tw-bg-opacity))
}

.bg-\[\#FFF0F0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 240 240 / var(--tw-bg-opacity))
}

.bg-\[\#f5f5f5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity))
}

.bg-\[\#fff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-\[\#fff\]\/80 {
  background-color: rgb(255 255 255 / 0.8)
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(27 63 252 / var(--tw-bg-opacity))
}

.bg-primary-dark\/50 {
  background-color: rgb(53 122 189 / 0.5)
}

.bg-primary-light {
  --tw-bg-opacity: 1;
  background-color: rgb(27 216 254 / var(--tw-bg-opacity))
}

.bg-secondary-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-secondary-l0 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-secondary-l2 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 242 247 / var(--tw-bg-opacity))
}

.bg-transparent {
  background-color: transparent
}

.bg-warn-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 244 199 / var(--tw-bg-opacity))
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5
}

.bg-opacity-70 {
  --tw-bg-opacity: 0.7
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.bg-none {
  background-image: none
}

.from-\[\#1104FE\] {
  --tw-gradient-from: #1104FE var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(17 4 254 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-\[\#1B3FFC\] {
  --tw-gradient-from: #1B3FFC var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(27 63 252 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-\[\#4a90e2\] {
  --tw-gradient-from: #4a90e2 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(74 144 226 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-\[\#FFC002\] {
  --tw-gradient-from: #FFC002 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 192 2 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-primary {
  --tw-gradient-from: #1b3ffc var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(27 63 252 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.to-\[\#1BD8FE\] {
  --tw-gradient-to: #1BD8FE var(--tw-gradient-to-position)
}

.to-\[\#E71010\] {
  --tw-gradient-to: #E71010 var(--tw-gradient-to-position)
}

.to-primary-light {
  --tw-gradient-to: #1bd8fe var(--tw-gradient-to-position)
}

.object-cover {
  object-fit: cover
}

.object-fill {
  object-fit: fill
}

.p-0 {
  padding: 0
}

.p-0\.5 {
  padding: 0.13333333333333333rem
}

.p-1 {
  padding: 0.26666666666666666rem
}

.p-2 {
  padding: 0.5333333333333333rem
}

.p-3 {
  padding: 0.8rem
}

.p-4 {
  padding: 1.0666666666666667rem
}

.p-5 {
  padding: 1.3333333333333333rem
}

.p-6 {
  padding: 1.6rem
}

.p-8 {
  padding: 2.1333333333333333rem
}

.p-\[0\.5px\] {
  padding: 0.5px
}

.p-\[10px\] {
  padding: 10px
}

.p-\[20px\] {
  padding: 20px
}

.\!px-2 {
  padding-left: 0.5333333333333333rem !important;
  padding-right: 0.5333333333333333rem !important
}

.\!py-1 {
  padding-top: 0.26666666666666666rem !important;
  padding-bottom: 0.26666666666666666rem !important
}

.px-1 {
  padding-left: 0.26666666666666666rem;
  padding-right: 0.26666666666666666rem
}

.px-2 {
  padding-left: 0.5333333333333333rem;
  padding-right: 0.5333333333333333rem
}

.px-3 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.px-4 {
  padding-left: 1.0666666666666667rem;
  padding-right: 1.0666666666666667rem
}

.px-6 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px
}

.py-1 {
  padding-top: 0.26666666666666666rem;
  padding-bottom: 0.26666666666666666rem
}

.py-2 {
  padding-top: 0.5333333333333333rem;
  padding-bottom: 0.5333333333333333rem
}

.py-3 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.py-4 {
  padding-top: 1.0666666666666667rem;
  padding-bottom: 1.0666666666666667rem
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px
}

.py-\[15px\] {
  padding-top: 15px;
  padding-bottom: 15px
}

.py-\[22px\] {
  padding-top: 22px;
  padding-bottom: 22px
}

.py-\[28px\] {
  padding-top: 28px;
  padding-bottom: 28px
}

.py-\[4\.7px\] {
  padding-top: 4.7px;
  padding-bottom: 4.7px
}

.py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px
}

.py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px
}

.\!pl-8 {
  padding-left: 2.1333333333333333rem !important
}

.pb-0 {
  padding-bottom: 0
}

.pb-1 {
  padding-bottom: 0.26666666666666666rem
}

.pb-2 {
  padding-bottom: 0.5333333333333333rem
}

.pb-4 {
  padding-bottom: 1.0666666666666667rem
}

.pb-\[56\.25\%\] {
  padding-bottom: 56.25%
}

.pl-0 {
  padding-left: 0
}

.pl-2 {
  padding-left: 0.5333333333333333rem
}

.pl-3 {
  padding-left: 0.8rem
}

.pr-3 {
  padding-right: 0.8rem
}

.pr-4 {
  padding-right: 1.0666666666666667rem
}

.pr-\[2px\] {
  padding-right: 2px
}

.pt-3 {
  padding-top: 0.8rem
}

.pt-4 {
  padding-top: 1.0666666666666667rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.\!text-right {
  text-align: right !important
}

.text-right {
  text-align: right
}

.font-sans {
  font-family: Roboto
}

.\!text-\[10px\] {
  font-size: 10px !important
}

.\!text-\[14px\] {
  font-size: 14px !important
}

.\!text-\[16px\] {
  font-size: 16px !important
}

.\!text-\[18px\] {
  font-size: 18px !important
}

.\!text-\[24px\] {
  font-size: 24px !important
}

.\!text-\[32px\] {
  font-size: 32px !important
}

.\!text-base {
  font-size: 1.0666666666666667rem !important
}

.\!text-xs {
  font-size: 0.8rem !important
}

.text-2xl {
  font-size: 1.6rem
}

.text-4xl {
  font-size: 2.4rem
}

.text-6xl {
  font-size: 4.266666666666667rem
}

.text-\[10px\] {
  font-size: 10px
}

.text-\[11px\] {
  font-size: 11px
}

.text-\[12px\] {
  font-size: 12px
}

.text-\[14px\] {
  font-size: 14px
}

.text-\[15px\] {
  font-size: 15px
}

.text-\[16px\] {
  font-size: 16px
}

.text-\[17px\] {
  font-size: 17px
}

.text-\[18px\] {
  font-size: 18px
}

.text-\[19px\] {
  font-size: 19px
}

.text-\[20px\] {
  font-size: 20px
}

.text-\[22px\] {
  font-size: 22px
}

.text-\[24px\] {
  font-size: 24px
}

.text-\[25px\] {
  font-size: 25px
}

.text-\[26px\] {
  font-size: 26px
}

.text-\[28px\] {
  font-size: 28px
}

.text-\[32px\] {
  font-size: 32px
}

.text-\[34px\] {
  font-size: 34px
}

.text-\[48px\] {
  font-size: 48px
}

.text-\[8px\] {
  font-size: 8px
}

.text-base {
  font-size: 1.0666666666666667rem
}

.text-lg {
  font-size: 1.2rem
}

.text-sm {
  font-size: 0.9333333333333333rem
}

.text-xl {
  font-size: 1.3333333333333333rem
}

.text-xs {
  font-size: 0.8rem
}

.\!font-bold {
  font-weight: 700 !important
}

.\!font-light {
  font-weight: 300 !important
}

.\!font-medium {
  font-weight: 500 !important
}

.\!font-normal {
  font-weight: 400 !important
}

.\!font-semibold {
  font-weight: 600 !important
}

.font-bold {
  font-weight: 700
}

.font-extralight {
  font-weight: 200
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.font-normal {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.font-thin {
  font-weight: 100
}

.capitalize {
  text-transform: capitalize
}

.\!normal-case {
  text-transform: none !important
}

.leading-4 {
  line-height: 1rem
}

.leading-5 {
  line-height: 1.25rem
}

.leading-6 {
  line-height: 1.5rem
}

.leading-\[28px\] {
  line-height: 28px
}

.tracking-\[0\.05em\] {
  letter-spacing: 0.05em
}

.tracking-widest {
  letter-spacing: 0.1em
}

.\!text-\[\#000\] {
  color: #000 !important
}

.\!text-\[\#000\]\/50 {
  color: rgb(0 0 0 / 0.5) !important
}

.\!text-\[\#000\]\/80 {
  color: rgb(0 0 0 / 0.8) !important
}

.\!text-\[\#2E6ADC\] {
  color: #2E6ADC !important
}

.\!text-\[\#C4FF47\] {
  color: #C4FF47 !important
}

.\!text-\[\#fff\] {
  color: #fff !important
}

.\!text-secondary {
  color: #333333 !important
}

.text-\[\#0000008a\] {
  color: #0000008a
}

.text-\[\#000\] {
  color: #000
}

.text-\[\#000\]\/50 {
  color: rgb(0 0 0 / 0.5)
}

.text-\[\#000\]\/80 {
  color: rgb(0 0 0 / 0.8)
}

.text-\[\#0067E8\] {
  color: #0067E8
}

.text-\[\#01A3FE\] {
  color: #01A3FE
}

.text-\[\#0222B0\] {
  color: #0222B0
}

.text-\[\#050505\] {
  color: #050505
}

.text-\[\#061B45\] {
  color: #061B45
}

.text-\[\#063292\] {
  color: #063292
}

.text-\[\#07287B\] {
  color: #07287B
}

.text-\[\#077BFF\] {
  color: #077BFF
}

.text-\[\#090D42\] {
  color: #090D42
}

.text-\[\#09168B\] {
  color: #09168B
}

.text-\[\#091A88\] {
  color: #091A88
}

.text-\[\#093BA5\] {
  color: #093BA5
}

.text-\[\#0A237E\] {
  color: #0A237E
}

.text-\[\#0A2470\] {
  color: #0A2470
}

.text-\[\#0A26A4\] {
  color: #0A26A4
}

.text-\[\#0A2ECC\] {
  color: #0A2ECC
}

.text-\[\#0A56C6\] {
  color: #0A56C6
}

.text-\[\#0B207B\] {
  color: #0B207B
}

.text-\[\#0C224A\] {
  color: #0C224A
}

.text-\[\#0C3897\] {
  color: #0C3897
}

.text-\[\#0C5EC4\] {
  color: #0C5EC4
}

.text-\[\#0C6AD6\] {
  color: #0C6AD6
}

.text-\[\#0CABCA\] {
  color: #0CABCA
}

.text-\[\#0D1983\] {
  color: #0D1983
}

.text-\[\#0D305D\] {
  color: #0D305D
}

.text-\[\#0E32B6\] {
  color: #0E32B6
}

.text-\[\#1086ED\] {
  color: #1086ED
}

.text-\[\#10A922\] {
  color: #10A922
}

.text-\[\#1351D6\] {
  color: #1351D6
}

.text-\[\#14488D\] {
  color: #14488D
}

.text-\[\#1560D0\] {
  color: #1560D0
}

.text-\[\#1B5AEC\] {
  color: #1B5AEC
}

.text-\[\#1B76EC\] {
  color: #1B76EC
}

.text-\[\#1C0E0E\] {
  color: #1C0E0E
}

.text-\[\#2B1A1A\] {
  color: #2B1A1A
}

.text-\[\#2D6ADC\] {
  color: #2D6ADC
}

.text-\[\#2D7EE3\] {
  color: #2D7EE3
}

.text-\[\#2E2E6A\] {
  color: #2E2E6A
}

.text-\[\#413D3D\] {
  color: #413D3D
}

.text-\[\#B73030\] {
  color: #B73030
}

.text-\[\#CA0000\] {
  color: #CA0000
}

.text-\[\#F4BB3F\] {
  color: #F4BB3F
}

.text-\[\#F9D100\] {
  color: #F9D100
}

.text-\[\#FBFCFF\] {
  color: #FBFCFF
}

.text-\[\#f00\] {
  color: #f00
}

.text-\[\#fff\] {
  color: #fff
}

.text-\[\#ffff\] {
  color: #ffff
}

.text-\[red\] {
  color: red
}

.text-primary {
  color: #1b3ffc
}

.text-secondary {
  color: #333333
}

.text-secondary-dark {
  color: #000000
}

.text-secondary-l0 {
  color: #ffffff
}

.text-secondary-l0\/90 {
  color: rgb(255 255 255 / 0.9)
}

.text-secondary-light {
  color: #666666
}

.text-warn {
  color: #fec301
}

.text-warn-dark {
  color: #ff4747
}

.\!no-underline {
  text-decoration-line: none !important
}

.no-underline {
  text-decoration-line: none
}

.\!shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.duration-300 {
  transition-duration: 300ms
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:\!bg-green-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 204 180 / var(--tw-bg-opacity)) !important
}

.hover\:bg-\[\#dad8d8\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(218 216 216 / var(--tw-bg-opacity))
}

.hover\:text-primary:hover {
  color: #1b3ffc
}

.hover\:underline:hover {
  text-decoration-line: underline
}

.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:border-primary:focus {
  border-color: #1b3ffc
}

@media (min-width: 960px) {
  .sm\:bottom-\[160px\] {
    bottom: 160px
  }

  .sm\:bottom-\[80px\] {
    bottom: 80px
  }

  .sm\:top-\[19\%\] {
    top: 19%
  }

  .sm\:top-\[21\%\] {
    top: 21%
  }

  .sm\:top-\[27\%\] {
    top: 27%
  }

  .sm\:top-\[41\.4\%\] {
    top: 41.4%
  }

  .sm\:h-\[1\%\] {
    height: 1%
  }

  .sm\:h-\[17\%\] {
    height: 17%
  }

  .sm\:h-\[76\.0\%\] {
    height: 76.0%
  }

  .sm\:max-h-\[630px\] {
    max-height: 630px
  }

  .sm\:max-h-\[650px\] {
    max-height: 650px
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }
}